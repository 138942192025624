import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ChartBandLight from "../components/ChartBandLight";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TrustPilot from "../components/TrustPilot";

export const IndexPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (

  <div>
    <Helmet>
      <title>Swiftfox | A better way to manage your international payments online</title>
    </Helmet>
    <div className="hero-section home-hero">
      <div className="hero-main home-hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Global transfers, <span><br/>made <i>swift.</i></span>
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Swiftfox gives you instant access to international money management tools. Allowing you to send or receive over 30 currencies worldwide.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" data-srcoll-to to="#discover">
                    Discover more
                  </Link>
                  <Link className="main-button-alt" to="open-an-account">
                    Open an account
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
        <div className="hero-video-overlay" />
        <div className="hero-video">
          <video loop autoPlay muted playsInline>
              <source src="/img/sf-home.webm" type="video/webm" />
              <source src="/img/sf-home.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      {/* <div className="hero-banner">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-content">
                <div className="section-item">
                  <div className="banner-images">
                    <img alt="Trust Pilot" src="../img/trust-pilot.svg"/>
                  </div>
                </div>
            </motion.div>
          )}
        </InView>
      </div> */}

      <div className="hero-banner-light">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }}
              className="hero-banner-ticker"
            >
              <ChartBandLight />
            </motion.div>
          )}
        </InView>
      </div>

    </div>
    <div className="callout-section dark-bg">
      <div className="callout-main">
        <div className="callout-main-content switcho">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>Moving money in seconds</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                With a Swiftfox account, you'll have the power of fast transfers and quick payments, anytime, anywhere. Create an account to start sending and receiving local and international payments today.
                <br/><br/>
                Thanks to our partnership with Currency Cloud, we’ve joined forces with Plaid to integrate their Payment Initiation Services (PIS) into our platform, making it easier than ever before to fund your accounts. This means that we’re rolling out this service in GBP initially via the UK Faster Payment Scheme (FPS). Payments up to £250,000 can now be made into your account 24/7 and normally arrive within a few moments.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-alt" to="open-an-account">
                  Get Access To fast Transfers
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item imagero">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.img ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} 
                src="../img/home-1.svg" width="350px" className="leanright"/> 
              )}
            </InView>
          </div>
        </div> 
      </div> 
    </div>
    <div className="callout-section callout-bg dark-text" id="discover">
      <div className="callout-main nogapok">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Bank beating rates
                </motion.h2>
              )}
            </InView>
          </div>
        </div>
        <div className="callout-main-content uptopok">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                Swiftfox puts your personal or business financial needs on the fast track to success with international payments that are more competitive and simple to use.
                </motion.h4>
              )}
            </InView>
          </div>
          <div className="section-item imagero">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                When you're sending money overseas, Swiftfox offers competitive exchange rates. Whether you set up a personal or corporate account with us, our expert team of dedicated traders will be at your disposal.
                </motion.h4>
              )}
            </InView>
          </div>
        </div> 
        <div className="callout-main-content">
          <div className="section-item white-text">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-alt" to="open-an-account">
                  Sign up Today
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section orange-bg white-text">
      <div className="callout-main">
        
        <div className="callout-main-content lessgapok">
          <div className="section-item white-text fadeytext">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h5 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1, ease: "easeInOut" }}>
                  Pay international mortgages, rent or bills without a local bank with our worldwide online payment service. 
                </motion.h5>
              )}
            </InView>
          </div>
          <div className="section-item white-text fadeytext">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h5 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1, ease: "easeInOut" }}>
                  Pay employees or suppliers in local currencies with competitive exchange rates in 70+ countries.
                </motion.h5>
              )}
            </InView>
          </div>
          <div className="section-item white-text fadeytext">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h5 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1, ease: "easeInOut" }}>
                  Pay up to 1,000 people in a single transaction with batch payments. 
                </motion.h5>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section white-bg">
      <div className="callout-main">
        <div className="callout-main-content switcho">
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>One-click international payment solutions</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Swiftfox is a financial company that provides a broad range of solutions for personal and business customers all over the world. From international payments to currency conversion, Swiftfox has an easy solution that can be found with just one quick click on our online platform.
                  <br/><br/>
                  For those who have multiple currencies already generating money around the globe. Sending funds becomes extremely fast and simple with either an individual or business account—you will never worry about getting money from one place to another again.
                  <br/><br/>
                  Swiftfox is your one-click, easy-pay solution to managing international money. Converting or moving funds within your account has never been so simple.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-alt" to="open-an-account">
                  Click here to create an account
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item imagero">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.img ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} 
                src="../img/home-3.svg" width="350px" className="leanright"/> 
              )}
            </InView>
          </div>
        </div> 
        <div className="callout-main-content">
          <div className="section-item">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack widtho">
                    <TrustPilot />
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item dark-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Fast, Reliable and Secure</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  The security and privacy of your data are of the highest importance to us. That’s why we’ve partnered with The Currency Cloud Limited as our payment service provider. They are one of the world's top payment engines, designed to protect the safety and security of customers' money.
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item imagero">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.img ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} 
                src="../img/home-4.svg" width="350px" className="leanleft"/> 
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Your money, in safe hands</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                We’ve partnered with The Currency Cloud Limited to provide safety for our customer’s money. If you're looking for a currency solution that provides security in an easy-to-use package, Swiftfox is your answer.
                <br/><br/>
                Unlike banks, we don't lend out your funds and instead focus on maintaining a high level of security.

                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-alt" to="open-an-account">
                  Secure your funds today
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div> 
      </div>
    </div>
    
    <div className="callout-section personal-hero dark-text" id="get-in-touch">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="button-container stacker">
                  <div className="blue-block">
                    <h4>Business Account</h4>
                    <p>You can get an account for free and make payments with the real exchange rate in 70+ countries.</p>
                    <Link to="business" className="main-button-alt">
                    Set up a Business Account
                    </Link>
                  </div>
                  <div className="grey-block">
                    <h4>Personal Account</h4>
                    <p>Get everything you need to make payments abroad, including a swift code, IBAN and more.</p>
                    <Link to="personal"  className="main-button-alt">
                    Set up a Personal Account
                    </Link>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  We’re here to help
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Swiftfox provides a range of products and services to keep you at the forefront of the latest industry standards. We take care of everything and provide bespoke solutions tailored to your specific needs.
                  <br/>
                  <br/>
                  Our skilled team is always available to help you with your business or personal fund transfers. No matter how big or small, we have got you covered. You can rest assured that when it comes to ongoing support, our team is there to help.

                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-alt" to="contact-us">
                  Get in touch
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
